<template>
    <div class="form">
        <input
                v-bind="$attrs"
                class="form-control"
                :class="{'is-invalid': error}"
                ref="input"
                @input="processInput"
                @keydown.enter.stop.prevent="onEnter"
        >
        <span class="field-icon"
              :class="{'error': error,'password': isPassword, 'visible-password': passwordIsVisible}"
        >
            <img src="../../assets/img/show-password-icon.png" alt=""
                 class="toggle-password-visibility-icon"
                 v-show="isPassword"
                 @click="togglePasswordVisibility"
            >
        </span>
        <small class="text-danger" v-show="error">{{error}}</small>
    </div>
</template>

<script>
    //
    export default {
        name: 'BaseInput',
        inheritAttrs: false, //Все атрибуты которые навешаны через родителя, будут не корневом елементе этого компонента, а на том где указано v-bind="$attrs"
        data() {
            return {
                inputData: '',
                passwordIsVisible: false
            }
        },
        props: {
            error: null,
            isPassword: null,
        },
        methods: {
            processInput(e) {
                this.inputData = e.target.value;
                this.$emit('input', e.target.value);
            },
            togglePasswordVisibility() {
                this.$refs.input.type === 'password' ? this.$refs.input.type = 'text' : this.$refs.input.type = 'password';
                this.passwordIsVisible = !this.passwordIsVisible;
            },
            onEnter(e) {
              this.$emit('enter', e)
            }
        }
    };
</script>

<style lang="scss" scoped>
    .icon {
        pointer-events: all !important;
    }

    span {
        &.visible-password {
            .toggle-password-visibility-icon {
                opacity: 1;
            }
        }

        .toggle-password-visibility-icon {
            cursor: pointer;
            opacity: 0.5;
        }
    }
    .help {
        text-align: left;
        margin-top: 0;
    }
    .is-invalid {
        margin-bottom: 0;
      background-image: none;

    }

    .field-icon {
        float: right;
        margin-right: 5px;
        margin-top: -32px;
        position: relative;
        z-index: 2;
    }


</style>
