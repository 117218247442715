<template>
  <div v-if="hasSocialAuth">
    <div> - or -</div>
    <div v-if='isDisabled'>
        Accept terms and conditions above and
    </div>
    <div v-if="loading">
      <loader />
    </div>
    <div class="d-flex justify-content-center social-wrapp m-4">
      <button v-if="googleKey" class="btn social" @click="useAuthProvider($options.PROVIDERS.GOOGLE)" :disabled='isDisabled'>
        <google-icon/>
        <span>{{ $t(btnTitle, {provider: "Google"}) }}</span>  
      </button>
      <button v-if="facebookKey" class="btn social" @click="useAuthProvider($options.PROVIDERS.FACEBOOK)" :disabled='isDisabled'>
        <facebook-icon/>
        <span>{{ $t(btnTitle, {provider: "Facebook"}) }}</span>
      </button>
      <button v-if="auth0Key" class="btn social" @click="loginWithAuth0" :disabled='isDisabled'>
        <img width="20" src='https://cdn.auth0.com/styleguide/components/1.0.8/media/logos/img/badge.png' />
        <span>{{ $t(btnTitle, {provider: "Auth0"}) }}</span>
        <span v-if="auth0Error">{{auth0Error}}</span>
      </button>
    </div>
  </div>
</template>

<script>
  import {Providers} from 'universal-social-auth'
  import {mapActions, mapState} from "vuex";
  import {AUTH_PROVIDERS, AUTH_TYPE} from "../../const";
  import GoogleIcon from "../../assets/img/GoogleIcon";
  import FacebookIcon from "../../assets/img/FacebookIcon";
  import Loader from "../../components/Loader";
  import { createAuth0Client } from '@auth0/auth0-spa-js';

  export default {
    name: "SocialAuth",
    components: {FacebookIcon, GoogleIcon, Loader},
    data() {
        return {
            apiKey: this.$storage.get('api_key'),
            loading: false,
            auth0Error: null
        }
    },
    PROVIDERS: AUTH_PROVIDERS,
    props: {
      type: {
        type: String,
        default: AUTH_TYPE.LOGIN
      },
      terms: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      ...mapActions(['DO_AUTH0_LOGIN', 'GET_PROFILE']),
      redirectAfterLogin() {
        this.$storage.get('last_page') ?
            this.$router.replace({
              path: this.$storage.get('last_page'),
              query: this.$storage.get('last_page_query')
            }) :
            this.$router.push({name: 'home'});
      },
      useAuthProvider(provider) {
        let ProData = Providers[provider];
        const providers = {
          google: {
            clientId: this.channelSettings?.social['google-login-key'],
            state: "api_key=" + this.$storage.get('api_key'),
          },
          facebook: {
            clientId: this.channelSettings?.social['facebook-login-key'],
            state: "api_key=" + this.$storage.get('api_key'),
          },
        };
        this.$Oauth.OverrideOptions.providers[provider] = {...this.$Oauth.OverrideOptions.providers[provider], ...providers[provider]};
        this.$Oauth.authenticate(provider, ProData).then(_ => {
          this.$store.commit('CLEAR_CACHE')
          this.GET_PROFILE().then(this.redirectAfterLogin);
        })
            .catch((err) => {
              console.log(err)
            })
      },
      async loginWithAuth0() {
        this.loading = true;
        const auth0_domain = this.channelSettings?.social['auth0-domain'];
        const auth0_client_id = this.channelSettings?.social['auth0-client-id'];
        const auth0_audience = this.channelSettings?.social['auth0-client-audience'];

        const auth0 = await createAuth0Client({
          domain: auth0_domain,
          clientId: auth0_client_id,
        });
        await auth0.loginWithPopup();
        
        const isAuthenticated = await auth0.isAuthenticated();
        if (isAuthenticated) {
          const accessToken = await auth0.getIdTokenClaims({
            authorizationParams: {
              audience: auth0_audience,
            },
          });
          this.DO_AUTH0_LOGIN({ api_key: this.apiKey, token: accessToken['__raw']})
          .then(() => {
              this.GET_PROFILE().then( () => {
                this.redirectAfterLogin();
              })
          })
          .catch((data) => {
              this.loading = false;
              console.log('Error data :>> ', data);
              this.auth0Error= "Incorrect auth";
          })
        } else {
          this.loading = false;
          this.auth0Error= 'User is NOT authenticated';
        }
      }

    },
    computed: {
      ...mapState({
        channelSettings: state => state.channel.settings,
      }),
      googleKey() {
        return this.channelSettings.social?.['google-login-key']
      },
      facebookKey() {
        return this.channelSettings.social?.['facebook-login-key']
      },
      auth0Key() {
        const auth0_domain = this.channelSettings?.social['auth0-domain'];
        const auth0_client_id = this.channelSettings?.social['auth0-client-id'];
        const auth0_audience = this.channelSettings?.social['auth0-client-audience'];
        return auth0_domain && auth0_client_id && auth0_audience
      },
      btnTitle() {
        return this.type === AUTH_TYPE.LOGIN ? 'Login with' : "Register with"
      },
      isRegister() {
        return this.type === AUTH_TYPE.REGISTRATION
      },
      isDisabled(){
        return this.type === AUTH_TYPE.REGISTRATION && !this.terms
      },
      hasSocialAuth() {
        return Object.values(this.channelSettings.social || {})?.some( k => k)
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "src/assets/css/mixins";

  .social {
    display: flex;
    align-self: center;
    gap: .5rem;
  }

  .social-wrapp {
    display: flex;
    @include media-max(md) {
      gap: .5rem;
      flex-direction: column;
    }
  }
</style>
