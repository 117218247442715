<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <h2 class="h2 card-header ">{{ $t('Login') }}</h2>
                    <div class="card-body">
                        <form>
                            <div class="row justify-content-center">
                                <BaseInput class="form-group col-md-6" v-model.trim="formData.email" type="text"
                                           placeholder="Email"
                                           autocomplete="username"
                                           :error="errors.emailError"></BaseInput>
                            </div>
                            <div class="row justify-content-center">
                                <BaseInput class="form-group col-md-6" v-model.trim="formData.password" type="password"
                                           isPassword="true"
                                           autocomplete="current-password"
                                           :placeholder="$t('Password')"
                                           :error="errors.passwordError"
                                           @keyup.enter="login"
                                           @enter="login"
                                ></BaseInput>
                            </div>
                        </form>
                        <div class="row justify-content-center">
                            <loader v-if="loading"/>
                            <button v-else class="btn m-4 login-btn" @click="login">
                                {{ $t('Login') }}
                            </button>
                            <router-link class="link m-4 pt-2"
                                         to="/password-reset-request"> {{ $t('Forgot Your Password?') }}
                            </router-link>
                        </div>
                        <social-auth/>
                        <div class="button-colors">
                            <p> {{ $t('Don`t have an account yet?') }} </p>
                            <router-link class="link" to="/register">{{ $t('Register')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {ERROR} from "../const";
    import { Providers } from 'universal-social-auth'
    import BaseInput from "../components/base-components/BaseInput";
    import Loader from "../components/Loader";
    import SocialAuth from "../components/base-components/SocialAuth";
    import {validateEmail} from "@/utils/helpers";

    export default {
        name: "LoginScreen",
        components: {SocialAuth, Loader, BaseInput},
        data() {
            return {
                apiKey: this.$storage.get('api_key'),
                loading: false,
                additionalParamsToErrormessage: null,
                formData: {
                    email: '',
                    password: ''
                },
                errors: {
                    emailError: '',
                    passwordError: '',
                }
            }
        },
        computed: {
            ...mapState({
                channelSettings: state => state.channel.settings,
            })
        },
        methods: {
            ...mapActions(['DO_LOGIN', 'GET_PROFILE']),
            redirectAfterLogin(){
                this.$storage.get('last_page') ?
                    this.$router.replace({
                        path: this.$storage.get('last_page'),
                        query: this.$storage.get('last_page_query')
                    }):
                    this.$router.push({name: 'home'});
            },
            login() {
              if (this.loading) return;

              this.clearErrors();
                if (!this.formData.email || !this.formData.password.length) {
                    this.loading = false;
                    !this.formData.email ? this.errors.emailError = 'Enter your Email' : null;
                    !this.formData.password.length ? this.errors.passwordError = 'The password is too short' : null;
                    return;
                }
                if (!validateEmail(this.formData.email)) {
                    this.errors.emailError = ERROR.WRONG_EMAIL;
                    return;
                }
                this.loading = true;
                this.DO_LOGIN({
                    email: this.formData.email,
                    password: this.formData.password
                })
                    .then(() => {
                        this.loading = false;
                        this.clearErrors();
                        this.redirectAfterLogin()
                    })
                    .catch((data) => {
                        this.loading = false;

                        if (data && data.error && data.message) {
                            data.message === ERROR.WRONG_AUTH_DATA ? this.errors.passwordError = "Incorrect password" : null;
                            data.message === ERROR.ALREADY_LOGGED ? this.errors.passwordError = "You are already logged in" : null;
                        }
                    })
            },
            clearErrors() {
                this.errors.emailError = '';
                this.errors.passwordError = '';
            },
            useAuthProvider(provider) {
                let ProData = Providers[provider];
                const providers = {
                    google: {
                        clientId: this.channelSettings?.social['google-login-key'],
                        state: "api_key="+this.$storage.get('api_key'),
                    },
                    facebook: {
                        clientId: this.channelSettings?.social['facebook-login-key'],
                        state: "api_key="+this.$storage.get('api_key'),
                    },
                };
                this.$Oauth.OverrideOptions.providers[provider] = {...this.$Oauth.OverrideOptions.providers[provider], ...providers[provider]};
                this.$Oauth.authenticate(provider, ProData).then((response) => {
                    this.GET_PROFILE().then( () => {
                        this.$router.push({name: 'profile'});
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
        created(){
          if (this.$store.getters.isLogged) {
            this.$storage.get('last_page') ?
                this.$router.replace({
                  path: this.$storage.get('last_page'),
                  params: this.$storage.get('last_page_params'),
                  query: this.$storage.get('last_page_query')
                }):
                this.$router.push({name: 'home'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/colors";
    .container {
        margin: 150px auto 0;
    }
    .link {
        color: $link-accent;
    }
    .login-btn{
      background-color: var(--settings-login-button-background);
      color: var(--settings-login-button-text-color);
      position: relative;
      z-index: 1;
      border-radius: .3rem;
      &:before{
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--settings-login-button-background);
        transition: all .2s linear;
        border-radius: .3rem;
      }
      &:hover{
        background-color: var(--settings-login-button-background);
        color: var(--settings-login-button-text-color);
        box-shadow: none;
        &:before{
          filter: blur(3px);
          opacity: .5;
          transform: translate(3px, 3px);
        }
      }
    }
    .entertainment{
        body{
            background-color: $black!important;
        }
        .container {
            margin: 70px auto 0;
        }
        .card-header{
            color: #F5F5F5;
        }
        .card{
            margin-top: 10rem;
            background-color: #080809;
            .button-colors{
                color: #d0d0d0;
            }
        }
        .card-header{
            background-color: #060607;
        }
        .link {
            color: $text-secondary;
            border-color: #1d2124;
        }
    }
</style>
